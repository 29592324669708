import React, { useState, useEffect } from "react";

const ScreenReader = ({ text, settings = { chunkLength: 99999 } }) => {
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [chunks, setChunks] = useState([]);

  useEffect(() => {
    chunkText(text, settings.chunkLength);
  }, []);

  const synth = window.speechSynthesis;

  const chunkText = (text, chunkLength) => {
    var temp = text
      .replace(/\s{2,}/g, "|")
      .replace(/([.?!] )\s*/g, "$1|")
      .split("|");
    temp.forEach((sentence, i) => {
      if (sentence.length > chunkLength) {
        var sentenceLength = sentence.length;
        while (sentenceLength > chunkLength) {
          var words = sentence.split(" ");
          const middleIndex = Math.ceil((words.length - 1) / 2);
          temp[i] = words.splice(0, middleIndex).join(" ");
          temp.splice(i + 1, 0, words.splice(-middleIndex).join(" "));
          sentenceLength = temp[i].length;
        }
      }
    });
    setChunks(temp);
  };

  const onEnd = () => {
    setIsSpeaking(false);
  };

  const onStop = () => {
    synth.cancel();
    setIsSpeaking(false);
  };

  const onSpeak = () => {
    if (isSpeaking) {
      onStop();
      return;
    }
    setIsSpeaking(true);

    chunks.forEach((chunk, i) => {
      var utterance = new SpeechSynthesisUtterance(chunk);

      i >= chunks.length - 1 && utterance.addEventListener("end", onEnd);

      if (settings.modifier) {
        settings.modifier(utterance);
      }
      setTimeout(function () {
        speechSynthesis.speak(utterance);
      }, 0);
    });
  };

  return (
    <button
      id="speak-button"
      className={`btn btn-link screen-reader${isSpeaking ? " active" : ""}`}
      onClick={onSpeak}
    >
      {isSpeaking ? (
        <span className="fas fa-stop"></span>
      ) : (
        <span className="fas fa-volume-up"></span>
      )}
    </button>
  );
};

export default ScreenReader;
